import moment from "moment"

const getDateNextMonday = (date: any) => {
  const dayNumber = date.isoWeekday()
  let dateNextMonday
  if (dayNumber !== 1) {
    dateNextMonday = moment(date).add(8 - dayNumber, "d")
  } else {
    dateNextMonday = moment(date)
  }
  return dateNextMonday
}

export default getDateNextMonday
