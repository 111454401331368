
import { Component, Prop, Vue } from "vue-property-decorator"
import { mapState } from "vuex"

@Component({
  name: "ButtonsWeekRow",
  computed: {
    ...mapState("plan", ["values"])
  }
})
export default class ButtonsWeekRow extends Vue {
  @Prop() private activeDays!: []
  private values!: any
  get weekdays() {
    const days = this.values.workoutDaysValues.map((v: any) => {
      return {
        value: v.value,
        name: v.name.slice(0, 3)
      }
    })
    return days
  }
}
