<template>
  <div class="info-stat">
    {{ name }}
    <b> {{ format ? getTime(value) : value }} </b>
  </div>
</template>

<script>
import moment from "moment"
import { i18n } from "@/main"

export default {
  name: "InfoStat",
  props: {
    name: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      default: null
    },
    format: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getTime(value) {
      const { locale } = i18n
      return moment
        .duration(value, "h")
        .locale(locale)
        .humanize()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.info-stat {
  white-space: nowrap;
  color: $gray7;
  font-size: 14px;
  font-weight: 100;
  text-transform: capitalize;

  b {
    white-space: nowrap;
    display: block;
    color: $gray6;
    font-weight: 500;
  }

  .icon {
    margin-right: 4px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
  }
}
</style>
