
import { Component, Vue } from "vue-property-decorator"
import { mapActions, mapState, mapGetters } from "vuex"
import HistoryHero from "@/components/history/HistoryHero.vue"
import HistoryItems from "@/components/history/HistoryItems.vue"
import HistoryNoPlansSection from "@/components/history/HistoryNoPlansSection.vue"

@Component({
  name: "History",
  components: {
    HistoryHero,
    HistoryItems,
    HistoryNoPlansSection
  },
  computed: {
    ...mapState("history", ["history"]),
    ...mapGetters(["getUserIdForDebugging", "isAdmin", "getFirstName"])
  },
  methods: {
    ...mapActions("history", ["getHistory"]),
    ...mapActions("plan", ["getValues"])
  }
})
export default class Question extends Vue {
  getHistory!: Function
  getFirstName!: Function
  getUserIdForDebugging!: Function
  getValues!: Function
  async beforeMount() {
    await this.getValues()
    await this.getHistory()
  }
}
