
import { Component, Prop, Vue } from "vue-property-decorator"
import InfoStat from "@/components/history/HistoryItem/InfoStat.vue"
import CloneModal from "@/components/history/HistoryItem/CloneModal.vue"
import ButtonsWeekRow from "@/components/history/HistoryItem/ButtonsWeekRow.vue"
import { mapGetters, mapState } from "vuex"
import configuration from "@/configuration"
import planTypeName from "@/mixins/planTypeName"
import { i18n } from "@/main"
import moment from "moment"
const { appUrl } = configuration
import getDateNextMonday from "@/mixins/getDateNextMonday"
import { BLOCK_TYPES, DATE_FORMAT } from "@/types"
import { PLAN_CREATOR_MODE, ROUTE_NAMES } from "@/defaults"
import dayjs from "dayjs"

@Component({
  name: "HistoryItem",
  mixins: [planTypeName],
  components: {
    InfoStat,
    ButtonsWeekRow,
    CloneModal
  },
  computed: {
    PLAN_CREATOR_MODE() {
      return PLAN_CREATOR_MODE
    },
    ROUTE_NAMES() {
      return ROUTE_NAMES
    },
    ...mapState(["isEmbedded"]),
    ...mapGetters(["isAdmin"])
  }
})
export default class HistoryItem extends Vue {
  @Prop() private history!: any
  private isEmbedded!: boolean
  private isAdmin!: boolean
  private showDeleteHistoryModal = false
  private showDeleteFromTDModal = false
  private showLegacyHistoryModal = false
  private showClonePlanModal = false
  get isLegacyHistory() {
    return !this.history.summary
  }
  get historyData() {
    if (this.isLegacyHistory) {
      const { plan, info, profileFacts } = this.history
      const isEventBasedPlan = plan.blocks[0].blockType !== BLOCK_TYPES.ANY && plan.firstPrimaryEvent
      let eventDate = ""
      if (isEventBasedPlan) {
        const lastEvent = [this.history.plan.firstPrimaryEvent, ...this.history.plan.multipleEvents].pop()
        eventDate = lastEvent.eventDate
      }

      return {
        isDcsPlan: plan.isDCS,
        eventDate,
        planName: info.name,
        startDate: plan.startDate,
        planSlug: this.history.trainerDayPlanSlug,
        calendarObjectId: this.history.trainerDayCalendarObjectId,
        mainBlock: info.block,
        workoutDays: info.block ? info.block.workoutDays : profileFacts.workoutDays,
        totalWeeks: plan.totalWeeks,
        ...(isEventBasedPlan && { events: [plan.firstPrimaryEvent, ...plan.multipleEvents] }),
        isEventBasedPlan,
        summary: plan.info,
        isDraft: this.history.isDraft
      }
    }
    const { planConfig, summary, trainerDayData } = this.history
    const { isEventBasedPlan } = planConfig
    let eventDate = ""
    if (isEventBasedPlan) {
      const lastEvent = [...this.history.planConfig.events].pop()
      eventDate = lastEvent.eventDate
    }
    return {
      isDcsPlan: planConfig.isDcs,
      eventDate,
      planName: planConfig.name,
      startDate: planConfig.startDate,
      planSlug: trainerDayData?.planSlug,
      calendarObjectId: trainerDayData?.calendarObjectId,
      mainBlock: summary.mainBlock,
      workoutDays: summary.mainBlock.days.filter((day: any) => day.name && day.isEnabled).map((el: any) => el.name),
      totalWeeks: summary.totalWeeks,
      events: planConfig.events,
      isEventBasedPlan,
      summary: summary,
      isDraft: this.history.isDraft
    }
  }
  get historyId() {
    return this.history._id
  }

  get planLink() {
    return this.historyData.planSlug ? `${appUrl}/plans/${this.historyData.planSlug}` : ""
  }
  get calendarLink() {
    if (this.$route.query.userId && this.isAdmin) {
      return `https://trainerday.com/login?redirect=${appUrl}/calendar&want-to-be=${this.$route.query.userId}`
    }
    return this.historyData.calendarObjectId ? `${appUrl}/calendar` : ""
  }
  get durationText() {
    const { totalWeeks } = this.historyData // TODO: any better way to get it?

    const isEventBasedPlan = this.historyData.isEventBasedPlan
    if (isEventBasedPlan) {
      return `${this.$t("historyItems.duration")}: ${dayjs(this.historyData.startDate).format("MMM D")} - ${dayjs(
        this.historyData.eventDate
      ).format("MMM D")}`
    } else {
      const weeksText = this.$tc("planDuration.weeksOrWeek", totalWeeks, { count: totalWeeks })
      return `${this.$t("historyItems.duration")}: ${weeksText}`
    }
  }

  openPlanInMyPlans() {
    if (this.isEmbedded) {
      window.parent.postMessage(
        {
          type: "open-plan-in-my-plans",
          planSlug: this.historyData.planSlug
        },
        appUrl
      )
    } else {
      window.location.href = this.planLink
    }
  }

  openPlanInCalendar() {
    if (this.isEmbedded) {
      window.parent.postMessage(
        {
          type: "open-plan-in-calendar",
          calendarId: this.historyData.calendarObjectId
        },
        appUrl
      )
    } else {
      window.location.href = this.calendarLink
    }
  }

  get deleteFromTDTitle() {
    let title = ""

    if (this.historyData.planSlug) {
      title = `${this.$t("historyItems.deleteFromMyPlansTitle")}`
    }
    if (this.historyData.calendarObjectId) {
      title = `${this.$t("historyItems.deleteFromCalendarTitle")}`
    }

    return title
  }
  get deleteFromTDText() {
    let text = ""

    if (this.historyData.planSlug) {
      text = `${this.$t("historyItems.deleteFromMyPlansText")}`
    }
    if (this.historyData.calendarObjectId) {
      text = `${this.$t("historyItems.deleteFromCalendarText")}`
    }

    return text
  }

  get dateTitle() {
    const { locale } = i18n
    const formatter = new Intl.DateTimeFormat(locale, { month: "short" })
    const date = new Date(this.history.createdAt)
    const month = formatter.format(date)
    const day = date.getDate()
    return `${month} ${day}`
  }

  get weeklyTimeTitle() {
    let weeklyMaxHours, weeklyMinHours
    if (this.historyData.summary) {
      ;({ weeklyMaxHours, weeklyMinHours } = this.historyData.summary)
    }
    if (this.historyData.summary && weeklyMaxHours) {
      if (weeklyMinHours === weeklyMaxHours) {
        return weeklyMinHours
      }

      return weeklyMinHours - weeklyMaxHours
    } else {
      return this.historyData.mainBlock.weeklyHoursMin
    }
  }

  deletePlan() {
    this.showDeleteHistoryModal = false
    if (!this.historyData.isDraft && (this.historyData.calendarObjectId || this.historyData.planSlug)) {
      this.showDeleteFromTDModal = true
    } else {
      this.deleteHistory(this.historyId, false)
    }
  }

  async deleteHistory(historyId: string, deleteFromTD: boolean) {
    await this.$store.dispatch("history/deleteHistory", { historyId, deleteFromTD })
    this.showDeleteFromTDModal = false
    this.$emit("loadHistory")
  }

  async clonePlan(startDate?: string) {
    const payload = {
      historyId: this.historyId,
      ...(startDate ? { startDate } : {})
    }
    const historyId = await this.$store.dispatch("history/clonePlan", payload)
    this.$router.push({ name: ROUTE_NAMES.PLAN, params: { mode: PLAN_CREATOR_MODE.EDIT }, query: { historyId } })
  }
  editClickHandler() {
    if (this.isLegacyHistory) {
      this.showLegacyHistoryModal = true
      return
    }
    const historyId = this.historyId
    this.$router.push({
      name: this.historyData.isDcsPlan && !this.isAdmin ? ROUTE_NAMES.PLAN_DCS : ROUTE_NAMES.PLAN,
      params: { mode: PLAN_CREATOR_MODE.EDIT },
      query: { historyId }
    })
  }
  cloneClickHandler() {
    if (this.isLegacyHistory) {
      this.showLegacyHistoryModal = true
      return
    }
    if (!this.historyData.isEventBasedPlan) {
      const newStartDate = getDateNextMonday(moment()).format(DATE_FORMAT)
      this.clonePlan(newStartDate)
    } else {
      this.showClonePlanModal = true
    }
  }
}
