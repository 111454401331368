import { mapActions, mapState } from "vuex"
import { Component, Vue } from "vue-property-decorator"
import { PLAN_TYPES } from "@/types"

@Component({
  computed: {
    ...mapState("plan", ["values", "config"])
  },
  methods: {
    ...mapActions("plan", ["getValues"])
  }
})
export default class extends Vue {
  private values: any
  private historyData: any
  private profileFacts: any
  private getValues!: Function

  get planTypeName() {
    const { planTypeValues, harderTypeValues } = this.values
    if (planTypeValues || harderTypeValues) {
      let pt = ""
      if (this.historyData.mainBlock) {
        pt = this.historyData.mainBlock.planType
      } else pt = this.profileFacts.planType
      if (pt === PLAN_TYPES.CUSTOM) return "Custom"
      return (
        planTypeValues.find((planType: any) => planType.value === pt)?.name ||
        harderTypeValues.find((planType: any) => planType.value === pt)?.name
      )
    }

    return ""
  }
}
