
import { Component, Prop, Vue } from "vue-property-decorator"
import HistoryItem from "@/components/history/HistoryItem/HistoryItem.vue"
import { i18n } from "@/main"
import { mapState } from "vuex"

@Component({
  name: "HistoryItems",
  components: {
    HistoryItem
  },
  computed: {
    ...mapState("plan", ["values"])
  }
})
export default class HistoryItems extends Vue {
  @Prop() private history!: []
  @Prop() private userIdForDebugging!: null
  @Prop() private isAdmin!: false
  @Prop() private userName!: ""
  get list() {
    // group by month
    const grouped = this.history.reduce((r: any, o: any) => {
      const m = o.createdAt.split("-")[1]
      // eslint-disable-next-line no-plusplus
      if (r[m]) {
        r[m].data.push(o)
      } else {
        const { locale } = i18n
        const date = new Date(o.createdAt)
        const dayDD = new Intl.DateTimeFormat(locale, { day: "2-digit" }).format(date)
        const month = new Intl.DateTimeFormat(locale, { month: "long" }).format(date)
        const monthMM = new Intl.DateTimeFormat(locale, { month: "2-digit" }).format(date)
        const yearYYYY = new Intl.DateTimeFormat(locale, { year: "numeric" }).format(date)
        r[m] = {
          monthLabel: `${month} ${yearYYYY}`,
          date: `${yearYYYY}-${monthMM}-${dayDD}`,
          data: [o]
        }
      }
      return r
    }, {})

    const results = Object.keys(grouped).map(k => grouped[k])

    // sorting groups by date
    results.sort((a, b) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime()
    })

    return results
  }
}
