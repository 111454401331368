<template>
  <BlueHeaderModal class="clone-modal" @close="$emit('close')">
    <template slot="header">
      <label class="label">{{ $t("cloneModal.title") }}</label>
      <div class="has-text-white">{{ planName }}</div>
    </template>
    <template slot="body">
      <div class="clone-modal-content">
        <img v-if="isLoading" class="page-loader-icon" src="@/assets/images/loader.gif" alt="Loader" />
        <template v-else>
          <div class="text-info">
            {{ $t("cloneModal.text1") }}<br />
            {{ $t("cloneModal.text2") }}
          </div>
          <ul>
            <li>
              {{ $t("planDuration.startingDate") }}: <b>{{ newStartDate }}</b>
            </li>
            <li>
              {{ $t("cloneModal.eventDate") }}: <b>{{ newEventDate }}</b>
            </li>
          </ul>
        </template>
      </div>
    </template>
    <template slot="footer">
      <button :disabled="isLoading" class="button is-full-width is-green" @click="submit">
        <span>{{ $t("cloneModal.clone") }}</span>
      </button>
    </template>
  </BlueHeaderModal>
</template>

<script>
import moment from "moment"
import getDateNextMonday from "@/mixins/getDateNextMonday"
import { DATE_FORMAT } from "@/types"

export default {
  name: "CloneModal",
  props: {
    planName: {
      type: String,
      default: ""
    },
    startDate: {
      type: String,
      default: null
    },
    eventDate: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      newStartDate: null,
      newEventDate: null,
      isLoading: false
    }
  },
  mounted() {
    this.newStartDate = getDateNextMonday(moment()).format(DATE_FORMAT)
    const daysBetween = moment(this.eventDate).diff(moment(this.startDate), "days")
    this.newEventDate = moment(this.newStartDate, DATE_FORMAT)
      .add(daysBetween, "days")
      .format(DATE_FORMAT)
  },
  methods: {
    async submit() {
      this.isLoading = true
      this.$emit("onSubmit", this.newStartDate)
      this.$emit("close")
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.scss";

.base-modal-mask {
  padding-top: 100px !important;
}
::v-deep {
  .blue-header-modal-header {
    padding: 15px 25px 10px 25px;
    .field {
      width: unset;
    }
  }
  .blue-header-modal-container {
    max-width: 380px;
    min-width: 380px;
  }
  .blue-header-modal-footer {
    padding: 15px 35px;
  }
}
.clone-modal {
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 94px;
    color: $gray8;
  }
  .text-info {
    text-align: center;
    margin-bottom: 10px;
  }
}
</style>
